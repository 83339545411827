import PropTypes from 'prop-types';
import { useParams, useNavigate } from 'react-router-dom';
// material-ui
import { useTheme } from '@mui/material/styles';
import { Avatar, Box, ButtonBase } from '@mui/material';

// project imports
import LogoSection from '../LogoSection';
import SearchSection from './SearchSection';
import DropDown from 'ui-component/atoms/DropDown';
import ProfileSection from './ProfileSection';
import NotificationSection from './NotificationSection';

// assets
import { IconMenu2 } from '@tabler/icons';
import { fontSize } from '@mui/system';
import { useDispatch } from 'react-redux';
import { SET_SELECTED_TYPE } from 'store/actions';

// ==============================|| MAIN NAVBAR / HEADER ||============================== //

const Header = ({ handleLeftDrawerToggle, dropDown }) => {
    const theme = useTheme();
    const params = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { client } = params;
    const handleChange = (e) => {
        const selectedClient = e.target.value;
        if (selectedClient !== client) {
            navigate(`/${selectedClient}`);
            dispatch({ type: SET_SELECTED_TYPE, payload: selectedClient });
        }
    };
    const dropDownComponent = dropDown.list ? (
        <DropDown list={dropDown.list} label="Clients" selectedValue={client} onChange={handleChange} />
    ) : null;
    return (
        <>
            {/* logo & toggler button */}
            <Box
                sx={{
                    width: 228,
                    display: 'flex',
                    [theme.breakpoints.down('md')]: {
                        width: 'auto'
                    }
                }}
            >
                <Box component="span" sx={{ display: { xs: 'none', md: 'none' }, flexGrow: 1 }}>
                    <LogoSection />
                </Box>
                {/* <ButtonBase sx={{ borderRadius: '12px', overflow: 'hidden' }}>
                    <Avatar
                        variant="rounded"
                        sx={{
                            ...theme.typography.commonAvatar,
                            ...theme.typography.mediumAvatar,
                            transition: 'all .2s ease-in-out',
                            background: theme.palette.secondary.light,
                            color: theme.palette.secondary.dark,
                            '&:hover': {
                                background: theme.palette.secondary.dark,
                                color: theme.palette.secondary.light
                            }
                        }}
                        onClick={handleLeftDrawerToggle}
                        color="inherit"
                    >
                        <IconMenu2 stroke={1.5} size="1.3rem" />
                    </Avatar>
                </ButtonBase> */}
            </Box>
            <Box sx={{ flexGrow: 1 }} />
            {dropDownComponent}
            {/* header search */}
            {/* <SearchSection /> */}
            {/* <Box sx={{ flexGrow: 1 }} /> */}
            <Box sx={{ flexGrow: 1 }} />
            {/* notification & profile */}
            {/* <NotificationSection /> */}
            <ProfileSection />
        </>
    );
};

Header.propTypes = {
    handleLeftDrawerToggle: PropTypes.func,
    dropDown: PropTypes.shape({
        selectedValue: PropTypes.string,
        list: PropTypes.arrayOf(
            PropTypes.shape({
                value: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
                displayName: PropTypes.string
            })
        )
    })
};
Header.defaultProps = {
    dropDown: {}
};

export default Header;
