// import clients from './clients';
// import pages from './pages';
// import utilities from './utilities';
import other from './other';

// ==============================|| MENU ITEMS ||============================== //
// clients data we will get from the service hit at the time of loading
const menuItems = {
    items: [other] // utilities
};

export default menuItems;
