import { useSelector } from 'react-redux';

import { ThemeProvider } from '@mui/material/styles';
import { SnackbarProvider } from 'notistack';
import { CssBaseline, StyledEngineProvider } from '@mui/material';

import Grow from '@material-ui/core/Grow';
// routing
import Routes from 'routes';

// defaultTheme
import themes from 'themes';

// project imports
import NavigationScroll from 'layout/NavigationScroll';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

// ==============================|| APP ||============================== //

const App = () => {
    const customization = useSelector((state) => state.customization);
    const params = useParams();
    // #todo: login logic should be written here
    // once the user is valid login then render to particular route
    // else redirect to JSSO
    useEffect(() => {
        console.log('app', params);
    }, []);

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={themes(customization)}>
                <SnackbarProvider
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left'
                    }}
                    TransitionComponent={Grow}
                    maxSnack={3}
                >
                    <CssBaseline />
                    <NavigationScroll>
                        <Routes />
                    </NavigationScroll>
                </SnackbarProvider>
            </ThemeProvider>
        </StyledEngineProvider>
    );
};

export default App;
