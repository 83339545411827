import axiosClient from 'network/apiClient';

export function getGSSOUserDetails(accessToken) {
    const data = {
        accessToken
    };
    return axiosClient.post(`/login/getuser`, JSON.stringify(data));
}

export function getGSSOAccessToken(tempCode) {
    const data = {
        tempCode
    };
    return axiosClient.post(`/login/getaccesstoken`, JSON.stringify(data));
}

export function getUser(data = {}) {
    return axiosClient.post(`/login/getuser`, JSON.stringify(data));
}
export function logoutUser() {
    return axiosClient.post(`/login/logout`, {}, { withCredentials: true });
}
