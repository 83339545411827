import { isEmptyOrNull } from 'utils/validations';

export const getParamNameValueString = (name, value, url = '') => (name && value ? `${url.includes('?') ? '&' : '?'}${name}=${value}` : '');

export const addQueryToURLfromObject = (url, queryObject = {}) => {
    if (!url) {
        return undefined;
    }
    if (isEmptyOrNull(queryObject)) {
        return url;
    }
    let finalURL = url;
    Object.keys(queryObject).forEach((key) => {
        finalURL += getParamNameValueString(key, queryObject[key], finalURL);
    });
    return finalURL;
};
export const getSortedQueryURL = () => {};
