import { useState, useEffect } from 'react';
import SortableTree from '@nosferatu500/react-sortable-tree';
import FileExplorerTheme from '@nosferatu500/theme-file-explorer';
import CreateNewFolderIcon from '@mui/icons-material/SearchOutlined';

import { actionButton } from './NodeButton';
import { updateTitle, updateIcon, getNodeKey, emptyTree } from './helper';
import './tree.scss';
import { PropTypes } from 'prop-types';

const NavTree = ({ treeData, updateTreeData, updateNewTypeNode, resetTablePagination, archivedTypeFn, isRootAdmin }) => {
    const [navTreeData, setNavTreeData] = useState(treeData || emptyTree());
    const [searchString, setSearchString] = useState('');
    const [searchFocusIndex, setSearchFocusIndex] = useState(0);

    useEffect(() => {
        setNavTreeData(treeData);
    }, [treeData]);

    const updateFolderName = (treeData, isRequestForUpdate) => {
        setNavTreeData(treeData);
        if (isRequestForUpdate) {
            updateTreeData(treeData, isRequestForUpdate);
        }
    };

    const updateTreeDataFn = (treeData, isRequestForUpdate, isRequestForMoveNode) => {
        setNavTreeData(treeData);
        if (isRequestForUpdate) {
            updateTreeData(treeData, isRequestForUpdate, isRequestForMoveNode);
        }
    };

    const updateNewTypeNodeFn = (data) => {
        updateNewTypeNode(data);
    };

    const customSearchMethod = ({ node, searchQuery }) => searchQuery && node.title.toLowerCase().indexOf(searchQuery.toLowerCase()) > -1;

    return (
        <div style={{ display: 'flex', flexDirection: 'column', height: 'calc(100% - 137px)', paddingBottom: '0px' }}>
            <div style={{ height: '90%' }}>
                <div className="nav_search_wrap">
                    <i>
                        <CreateNewFolderIcon />
                    </i>
                    <input
                        className="nav_search"
                        type="text"
                        placeholder="Search Configurations"
                        value={searchString}
                        onChange={(ev) => setSearchString(ev.target.value)}
                    />
                </div>
                <SortableTree
                    rowHeight={45}
                    theme={FileExplorerTheme}
                    treeData={navTreeData}
                    searchQuery={searchString}
                    searchMethod={customSearchMethod}
                    className="sortableTreeClass"
                    canDrag={({ node }) => !node.dragDisabled && isRootAdmin}
                    canDrop={({ nextParent }) => (!nextParent || nextParent.isDirectory) && isRootAdmin}
                    onChange={(data) => updateTreeDataFn(data)}
                    onMoveNode={({ treeData }) => updateTreeDataFn(treeData, true, true)}
                    getNodeKey={getNodeKey}
                    searchFinishCallback={(matches) => {
                        setSearchFocusIndex(matches.length > 0 ? searchFocusIndex % matches.length : 0);
                    }}
                    generateNodeProps={(rowInfo) => ({
                        title: updateTitle(rowInfo, navTreeData, updateTreeDataFn, updateFolderName, resetTablePagination),
                        icons: updateIcon(rowInfo),
                        buttons: actionButton(rowInfo, navTreeData, updateTreeDataFn, updateNewTypeNodeFn, archivedTypeFn, isRootAdmin)
                    })}
                />
            </div>
        </div>
    );
};

NavTree.propTypes = {
    treeData: PropTypes.object,
    updateTreeData: PropTypes.func.isRequired,
    updateNewTypeNode: PropTypes.func.isRequired,
    resetTablePagination: PropTypes.func
};

export default NavTree;
