import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default function AlertDialog({ dialogStatus, title = '', description = '', handleClickAgree, handleClickDisagree }) {
    return (
        <Dialog
            fullWidth
            open={dialogStatus}
            onClose={handleClickDisagree}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            {title && (
                <DialogTitle sx={{ fontSize: '20px', fontWeight: '700' }} id="alert-dialog-title">
                    {title}
                </DialogTitle>
            )}
            <DialogContent>
                <DialogContentText sx={{ fontSize: '14px', color: '#000' }} id="alert-dialog-description">
                    {description}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    sx={{
                        fontSize: '14px',
                        color: '#fff',
                        border: '1px solid #4055F5',
                        outline: 'none',
                        padding: '6px 8px',
                        borderRadius: '4px',
                        fontWeight: '600',
                        textTransform: 'uppercase',
                        lineHeight: '18px',
                        boxShadow: 'none',
                        marginRight: '8px',
                        background: '#4055F5',
                        minWidth: '120px',
                        '&:hover': {
                            background: '#4055F5'
                        }
                    }}
                    onClick={handleClickDisagree}
                >
                    Disagree
                </Button>
                <Button
                    sx={{
                        fontSize: '14px',
                        color: '#000',
                        border: '1px solid #000',
                        outline: 'none',
                        padding: '6px 8px',
                        borderRadius: '4px',
                        fontWeight: '600',
                        textTransform: 'uppercase',
                        lineHeight: '18px',
                        boxShadow: 'none',
                        background: 'none',
                        minWidth: '120px',
                        '&:focus': {
                            outline: 'none'
                        }
                    }}
                    onClick={handleClickAgree}
                    autoFocus
                >
                    Agree
                </Button>
            </DialogActions>
        </Dialog>
    );
}
