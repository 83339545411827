import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import { capitalize } from '@mui/material';

import './DropDown.scss';

export default function DropDown(props) {
    const { label, list, onChange, selectedValue } = props;
    const [ddValue, setDdValue] = React.useState(selectedValue || '');

    if (!list) {
        return null;
    }
    const handleChange = (event) => {
        setDdValue(event.target.value);
        onChange(event);
    };

    return (
        <Box sx={{ textAlign: 'left' }}>
            <FormControl fullWidth className="fullWidth">
                {/* <InputLabel id={`${label}_select_label`}>{label}</InputLabel> */}
                <Select
                    labelId={`${label}_select_label`}
                    id={`${label}_select_dropdown`}
                    value={ddValue}
                    label={label}
                    className="custom"
                    sx={{
                        fontSize: 20,
                        textTransform: 'capitalize',
                        fontWeight: 'bold'
                    }}
                    onChange={handleChange}
                    variant="standard"
                >
                    {list.map((obj) => (
                        <MenuItem
                            key={obj.value}
                            value={obj.value}
                            sx={{
                                fontSize: 16,
                                textTransform: 'capitalize'
                            }}
                        >
                            {obj.displayName}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Box>
    );
}

DropDown.propTypes = {
    label: PropTypes.string,
    onChange: PropTypes.func,
    selectedValue: PropTypes.string,
    list: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.string,
            displayName: PropTypes.oneOfType([PropTypes.string, PropTypes.node])
        })
    ).isRequired
};
DropDown.defaultProps = {
    label: '',
    selectedValue: undefined
};
