import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';

const Client = Loadable(lazy(() => import('views/client/ClientDetails')));
// const CreateClient = Loadable(lazy(() => import('views/client/CreateClient')));

const RenderType = Loadable(lazy(() => import('views/type/RenderType')));
const CreateType = Loadable(lazy(() => import('views/type/CreateType')));
const DraftList = Loadable(lazy(() => import('views/DraftList')));

const RenderTypeInstance = Loadable(lazy(() => import('views/type/renderTypeInstance/index')));
const CreateBulkInstance = Loadable(lazy(() => import('views/type/createBulkInstance/index')));
const NoMatchFound = Loadable(lazy(() => import('views/nomatchfound/NoMatchFound')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/:client',
    element: <MainLayout />,
    children: [
        {
            path: '/',
            element: <Client />
        },
        {
            path: '/draft',
            element: <DraftList />
        },
        {
            path: '/create-type',
            element: <CreateType edit={false} />
        },
        {
            path: '/:type',
            element: <RenderType />
        },
        {
            path: '/:type/edit/:id',
            element: <CreateType edit />
        },
        {
            path: '/:type/batch-create/:id',
            element: <CreateBulkInstance />
        },
        {
            path: '/:type/create-instance',
            element: <RenderTypeInstance isCreateInstanceRoute />
        },
        {
            path: '/:type/:id',
            element: <RenderTypeInstance />
        },
        {
            path: '/test-table',
            element: <CreateType />
        },
        {
            path: '*',
            element: <NoMatchFound />
        }
    ]
};

export default MainRoutes;
