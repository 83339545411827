// material-ui
import _GET from 'lodash.get';
import _SET from 'lodash.set';

import menuItem from 'menu-items';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useNavigate, Link } from 'react-router-dom';
import NavGroup from './NavGroup';
import NavTree from './NavTree/NavTree'; // #todo need to check
import { updateTypeInstance, getTypeInstanceByFilters } from 'network/lib/typeInstance';
import { SET_SELECTED_CLIENT, SET_NEW_TYPE_NODE, SET_CURRENT_PAGE, SET_CURRENT_NAV } from 'store/actions';
import LogOut from '../LogOut/LogOut';
import { logoutUser } from 'network/lib/login';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { archivedTypeSchema, unArchivedTypeSchema } from 'network/lib/type';
import { useSnackbar } from 'notistack';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import CreateNewFolderIcon from '@mui/icons-material/InsertDriveFileOutlined';
import IconButton from '@mui/material/IconButton';
import ReplayIcon from '@mui/icons-material/ReplayOutlined';

import { useEffect, useState } from 'react';
import { Tooltip } from '@mui/material';
import AlertDialog from 'views/common/AlertDialog';
import { isRootAdmin } from 'utils/common'; // isRootOrClientAdmin
import { useTheme } from '@mui/material/styles';

const defaultNavigation = {
    type: 'tree',
    className: 'root',
    children: [
        {
            id: 'configuration',
            edit: false,
            isDirectory: true,
            expanded: true,
            removeCancelButton: false,
            title: 'Configuration',
            children: []
        }
    ]
};

const MenuList = (props) => {
    const { client } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const theme = useTheme();
    const { navigationData = defaultNavigation } = props;

    const otherMenuItems = menuItem.items;
    const userData = useSelector((state) => state.user);
    const { currentNav = {} } = useSelector((state) => state.customization);

    const [expandedAccordian, setExpandedAccordian] = useState('navigation');
    const [archivedTypes, setArchivedTypes] = useState([]);
    const [restoreArchivedDialog, setRestoreArchivedDialog] = useState(false);
    const [selectedArchivedNode, setSelectedArchivedNode] = useState({});

    const getArchivedTypes = () => {
        getTypeInstanceByFilters(client, `types`, { j_archived: true }).then(({ data = [] }) => {
            setArchivedTypes(data);
        });
    };

    useEffect(() => {
        getArchivedTypes();
        dispatch({ type: SET_CURRENT_NAV, currentNav: navigationData });
    }, [navigationData]);

    const handleAccordianChange = (panel) => {
        setExpandedAccordian(panel);
    };

    if (!navigationData) {
        return null;
    }

    const menuItems = [navigationData, ...otherMenuItems];

    const updateTreeData = async (data, isRequestForUpdate = false, isRequestForMoveNode = false) => {
        try {
            const clientData = _GET(userData, `clients.${client}.details`);
            let updatedNavigationData;
            const queryParam = {};

            if (clientData.j_json && clientData.j_json.navigation) {
                updatedNavigationData = { ...clientData.j_json.navigation, children: data };
            } else {
                updatedNavigationData = { type: 'tree', className: 'root', children: data };
            }

            const updatedClientData = { ...clientData, j_json: { navigation: updatedNavigationData } };

            const payload = {
                ...updatedClientData,
                j_meta: updatedClientData.j_meta,
                j_json: updatedClientData.j_json
            };

            if (isRequestForUpdate) {
                queryParam.requestType = 'update_navigation_node';
                queryParam.requestClient = client;
            }

            if (isRequestForMoveNode) {
                queryParam.requestType = 'move_navigation_node';
                queryParam.requestClient = client;
            }

            const draftDBData = await updateTypeInstance('core', 'clients', clientData.id, payload, 'drafts', queryParam);
            const publishDBData = await updateTypeInstance('core', 'clients', clientData.id, payload, 'published', queryParam);
            if (draftDBData && publishDBData && draftDBData.data && publishDBData.data) {
                dispatch({ type: SET_SELECTED_CLIENT, payload: _SET(userData.clients, `${client}.details`, publishDBData.data) });
            }
        } catch (err) {
            const { data = {} } = err.response;

            enqueueSnackbar(`Error while updating the navigation : ${data.message || (err.toJSON() && err.toJSON().message)}`, {
                variant: 'error'
            });
        }
    };

    const archivedTypeFn = (node) => {
        const { id: nodeType } = node;
        archivedTypeSchema(client, nodeType, node)
            .then(({ data }) => {
                if (data.status === 'success') {
                    enqueueSnackbar('Type archived successfully', {
                        variant: 'success'
                    });
                }
            })
            .catch((err) => {
                const { data = {} } = err.response;
                enqueueSnackbar(`Error while archiving type : ${data.message}`, {
                    variant: 'error'
                });
            });
    };

    const unArchivedTypeFn = () => {
        unArchivedTypeSchema(client, selectedArchivedNode.j_id)
            .then(({ data }) => {
                getArchivedTypes();
                if (data.status === 'success') {
                    enqueueSnackbar('Type unarchived successfully', {
                        variant: 'success'
                    });
                }
            })
            .catch((err) => {
                const { data = {} } = err.response;
                enqueueSnackbar(`Error while unarchiving type : ${data.message}`, {
                    variant: 'error'
                });
            });
    };

    const updateNewTypeNode = (data) => {
        dispatch({ type: SET_NEW_TYPE_NODE, payload: data });
    };

    const handleLogout = async () => {
        await logoutUser();
        navigate('/');
    };

    const resetTablePagination = () => {
        dispatch({ type: SET_CURRENT_PAGE, currentPage: 0 });
    };

    const moveArchivedTypeToNavigation = async () => {
        const clientData = _GET(userData, `clients.${client}.details`);
        const { j_navigation_node: navigationNode, j_id: jID } = selectedArchivedNode;

        const typeNode = {
            id: jID,
            title: navigationNode.title || jID,
            isType: true
        };

        const rootNode = _GET(currentNav, `children[0]`, {});
        if (rootNode && rootNode.children) {
            _SET(rootNode, 'children', [typeNode, ...rootNode.children]);
        }

        const updatedClientData = { ...clientData, j_json: { navigation: currentNav } };

        const payload = {
            ...updatedClientData,
            j_meta: updatedClientData.j_meta,
            j_json: updatedClientData.j_json
        };
        const draftDBData = await updateTypeInstance('core', 'clients', clientData.id, payload);
        const publishDBData = await updateTypeInstance('core', 'clients', clientData.id, payload, 'published');
        if (draftDBData && publishDBData && draftDBData.data && publishDBData.data) {
            dispatch({ type: SET_SELECTED_CLIENT, payload: _SET(userData.clients, `${client}.details`, publishDBData.data) });
        }
    };

    const navItems = menuItems.map((item) => {
        switch (item.type) {
            case 'tree': {
                return (
                    <NavTree
                        key="root1"
                        treeData={item?.children}
                        updateTreeData={updateTreeData}
                        updateNewTypeNode={updateNewTypeNode}
                        resetTablePagination={resetTablePagination}
                        archivedTypeFn={archivedTypeFn}
                        isRootAdmin={isRootAdmin(userData)}
                    />
                );
            }

            case 'group':
                return <NavGroup key={item.id} item={item} />;
            default:
                return (
                    <Typography key={item.id} variant="h6" color="error" align="center">
                        Menu Items Error
                    </Typography>
                );
        }
    });

    return (
        <>
            <Accordion
                expanded={expandedAccordian === 'navigation'}
                onChange={() => handleAccordianChange('navigation')}
                sx={{ marginTop: '0px', marginBottom: '0px' }}
            >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon sx={{ color: '#000' }} />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                >
                    <Typography sx={{ flexShrink: 0, color: '#000', fontWeight: '600', fontSize: '13px' }}>Active Configs</Typography>
                </AccordionSummary>
                <AccordionDetails style={{ padding: '0px' }}>{navItems}</AccordionDetails>
            </Accordion>
            <Accordion expanded={expandedAccordian === 'archived'} onChange={() => handleAccordianChange('archived')}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon sx={{ color: '#000' }} />}
                    aria-controls="panel2bh-content"
                    id="panel2bh-header"
                    sx={{ minHeight: '0px' }}
                >
                    <Typography sx={{ flexShrink: 0, color: '#000', fontWeight: '600', fontSize: '13px' }}>Archived Configs</Typography>
                </AccordionSummary>
                <AccordionDetails style={{ padding: '0px', paddingBottom: '140px' }}>
                    <List sx={{ padding: '0px' }}>
                        {archivedTypes.length ? (
                            archivedTypes.map((item) => (
                                <ListItem
                                    sx={{ wordBreak: 'break-all' }}
                                    secondaryAction={
                                        <IconButton
                                            id={item.j_id}
                                            edge="end"
                                            aria-label="restore_type"
                                            onClick={() => {
                                                setSelectedArchivedNode(item);
                                                setRestoreArchivedDialog(true);
                                            }}
                                        >
                                            <Tooltip title="Restore Type">
                                                <ReplayIcon />
                                            </Tooltip>
                                        </IconButton>
                                    }
                                >
                                    <ListItemIcon>
                                        <CreateNewFolderIcon />
                                    </ListItemIcon>
                                    <Link to={`/${client}/${item.j_id}`}>
                                        <ListItemText primary={(item.j_navigation_node && item.j_navigation_node.title) || item.j_id} />
                                    </Link>
                                </ListItem>
                            ))
                        ) : (
                            <div className="no-draft tree-no-data">
                                <div className="inner">
                                    <img src="https://static.symplor.com/public/images/no-data.webp" alt="No instance in draft" />
                                    <h6>No Data</h6>
                                </div>
                            </div>
                        )}
                    </List>
                </AccordionDetails>
            </Accordion>
            <Link to={`/${client}/draft`} className="draft-btn">
                <span>Config Drafts</span>
                <ArrowForwardIcon
                    sx={{
                        color: theme.palette.orange.default
                    }}
                />
            </Link>
            <LogOut userData={userData} handleLogout={handleLogout} />
            <AlertDialog
                dialogStatus={restoreArchivedDialog}
                title="Are you sure ?"
                description="It will move instance to root level."
                handleClickDisagree={() => setRestoreArchivedDialog(false)}
                handleClickAgree={() => {
                    unArchivedTypeFn();
                    moveArchivedTypeToNavigation();
                    setRestoreArchivedDialog(false);
                }}
            />
        </>
    );
};

MenuList.propTypes = {
    navigationData: PropTypes.shape({
        navigation: PropTypes.arrayOf(PropTypes.shape({}))
    }).isRequired
};
MenuList.defaultProps = {};

export default MenuList;
