import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, Drawer, useMediaQuery } from '@mui/material';

// third-party
import PerfectScrollbar from 'react-perfect-scrollbar';
import { BrowserView, MobileView } from 'react-device-detect';

// project imports
import MenuList from './MenuList';
import LogoSection from '../LogoSection';
import DropDown from 'ui-component/atoms/DropDown';
// import MenuCard from './MenuCard';
import { drawerWidth } from 'store/constant';

import { SET_SELECTED_TYPE } from 'store/actions';

// ==============================|| SIDEBAR DRAWER ||============================== //

const Sidebar = ({ drawerOpen, drawerToggle, window, dropDown }) => {
    const theme = useTheme();
    const params = useParams();
    const navigate = useNavigate();
    const userData = useSelector((state) => state.user);
    const [navigationData, setNavigationData] = useState({});
    const matchUpMd = useMediaQuery(theme.breakpoints.up('md'));
    const { client } = params;
    const dispatch = useDispatch();
    const handleChange = (e) => {
        const selectedClient = e.target.value;
        if (selectedClient !== client) {
            navigate(`/${selectedClient}`);
            dispatch({ type: SET_SELECTED_TYPE, payload: selectedClient });
        }
    };

    const loadNavigationData = () => {
        const { clients } = userData;
        // eslint-disable-next-line camelcase
        const { j_json = {} } = clients[client].details;
        // #todo use _get
        setNavigationData(j_json.navigation);
    };

    useEffect(() => {
        if (client) {
            // eslint-disable-next-line no-debugger
            // debugger;
            loadNavigationData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [client, userData]);

    const dropDownComponent =
        dropDown && dropDown.list ? <DropDown list={dropDown.list} label="Clients" selectedValue={client} onChange={handleChange} /> : null;
    const drawer = (
        <>
            <Box sx={{ display: { xs: 'block', md: 'block' } }}>
                <Box sx={{ display: 'flex', padding: '20px', background: '#FAFAFA', mx: 'auto' }}>
                    <LogoSection />
                </Box>
            </Box>
            <div className="clientsSelect">
                <h6>Client</h6>
                {dropDownComponent}
            </div>
            <BrowserView>
                <PerfectScrollbar
                    component="div"
                    style={{
                        height: !matchUpMd ? 'calc(100vh - 56px)' : 'calc(100vh - 88px)',
                        paddingLeft: '0px',
                        paddingRight: '0px'
                    }}
                >
                    <MenuList navigationData={navigationData} />
                </PerfectScrollbar>
            </BrowserView>
            <MobileView>
                <Box sx={{ px: 2 }}>
                    <MenuList navigationData={navigationData} />
                </Box>
            </MobileView>
        </>
    );

    const container = window !== undefined ? () => window.document.body : undefined;

    return (
        <Box component="nav" sx={{ flexShrink: { md: 0 }, width: matchUpMd ? drawerWidth : 'auto' }} aria-label="mailbox folders">
            <Drawer
                container={container}
                variant={matchUpMd ? 'persistent' : 'temporary'}
                anchor="left"
                open={drawerOpen}
                onClose={drawerToggle}
                sx={{
                    '& .MuiDrawer-paper': {
                        width: drawerWidth,
                        background: theme.palette.background.default,
                        color: theme.palette.text.primary,
                        borderRight: 'none',
                        [theme.breakpoints.up('md')]: {
                            top: '88px'
                        }
                    }
                }}
                ModalProps={{ keepMounted: true }}
                color="inherit"
            >
                {drawer}
            </Drawer>
        </Box>
    );
};

Sidebar.propTypes = {
    drawerOpen: PropTypes.bool,
    drawerToggle: PropTypes.func,
    window: PropTypes.object
};
Sidebar.defaultProps = {};

export default Sidebar;
