function getUniqueId() {
    let rnd = '0';
    let text = '';
    const possible = 'ABCDEFGHIkLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz123456789';
    for (let i = 0; i < 3; i += 1) {
        text += possible.charAt(Math.floor(Math.random() * possible.length)) + Math.floor(Math.random() * 999 + 1);
    }
    rnd = text;

    return rnd;
}
export default getUniqueId;
