import axios from 'axios';
import { JEANIE_SERVER_URL } from 'utils/constants';
// const apiClient = async (url = '', bodyReq = {}) => {
//     const response = await fetch(url, {
//         method: 'POST', // *GET, POST, PUT, DELETE, etc.
//         mode: 'cors', // no-cors, *cors, same-origin
//         cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
//         credentials: 'same-origin', // include, *same-origin, omit
//         headers: {
//             'Content-Type': 'application/json'
//             // 'Content-Type': 'application/x-www-form-urlencoded',
//         },
//         redirect: 'follow', // manual, *follow, error
//         referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
//         body: JSON.stringify(bodyReq) // body data type must match "Content-Type" header
//     });
//     return response.json(); // parses JSON response into native JavaScript objects
// };

const axiosClient = axios.create({
    baseURL: `${JEANIE_SERVER_URL}/v1`,
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
    },
    withCredentials: true
});

// axiosClient.interceptors.response.use(
//     (response) => response,
//     (error) => {
//         const res = error;
//         // if (res.status === 401) {
//         //     // window.location.href = 'https://example.com/login';
//         // }
//         //   console.error(“Looks like there was a problem. Status Code: “ + res.status);
//         return Promise.reject(error);
//     }
// );

export default axiosClient;
