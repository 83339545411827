import { useState } from 'react';
import PropTypes from 'prop-types';
import getUniqueId from 'utils/helper';

const Buttons = (props) => {
    const { isDisabled, label, id, icon } = props;

    const onButtonClick = () => {
        // OnChange(!isChecked);
        // setIsChecked(!isChecked);
    };

    return (
        <button id={id || getUniqueId()} disabled={isDisabled} type="button" className="btn btn-info full-width" onClick={onButtonClick}>
            {label || 'button'} <i />
        </button>
    );
};

export default Buttons;
Buttons.propTypes = {
    label: PropTypes.string,
    id: PropTypes.string,
    icon: PropTypes.string,
    isDisabled: PropTypes.bool
    // value: PropTypes.bool,
    // OnChange: PropTypes.func.isRequired,
    // name: PropTypes.string
};
Buttons.defaultProps = {
    label: '',
    id: '',
    icon: '',
    isDisabled: false
    // icon: '',
    // className: '',
    // linkoutTarget: undefined,
    // layout: '',
    // isMobile: true
};
