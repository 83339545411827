// material-ui
import { useTheme } from '@mui/material/styles';
import { flexbox } from '@mui/system';
import logoJeanie from 'assets/images/logo-jeanie.svg';

/**
 * if you want to use image instead of <svg> uncomment following.
 *
 * import logoDark from 'assets/images/logo-jeanie.svg';
 * import logo from 'assets/images/logo.svg';
 *
 */

// ==============================|| LOGO SVG ||============================== //

const Logo = () => {
    const theme = useTheme();

    return (
        /**
         * if you want to use image instead of svg uncomment following, and comment out <svg> element.
         *
         * <img src={logo} alt="Berry" width="100" />
         *
         */
        // <span style={{ height: '34px' }}>
        // <img src="https://static.symplor.com/public/images/jeanie.bmp" alt="JEANIE Portal" width="158" />  New Img
        //     <img style={{ width: '30px' }} src="/jeanie.png" alt="JEANIE Portal" width="100" />
        //     <h4 style={{ fontSize: '24px', display: 'inline-block', marginLeft: '10px', position: 'relative', top: '5px' }}>J.A.R.V.I.S</h4>
        // </span>
        <span style={{ display: 'flex', alignItems: 'center' }}>
            <img src={logoJeanie} alt="Logo" style={{ width: '40px', display: 'inline-block' }} />
            <span style={{ fontSize: '22px', fontWeight: 'bold' }}>Jeanie</span>
        </span>
    );
};

export default Logo;
