/* eslint-disable no-nested-ternary */
export const J_ID = 'j_id';
export const J_ACCESS_TOKEN = 'j_ldap_sso_access_token';
export const J_LDAP_USER_DETAILS = 'ldap_sso_user_details';
const { NODE_ENV, REACT_APP_IS_STAGING } = process.env;

export const PUBLISHED = 'published';
export const DRAFTS = 'drafts';

const stagingServerUrlByHostname = () => {
    switch (window.location.hostname) {
        case 'jeanie-portal-stg.symplor.com':
            return 'https://jeanie-server-stg.symplor.com';
        case 'jeanie-portal-stg1.symplor.com':
            return 'https://jeanie-server-stg1.symplor.com';
        case 'jeanie-portal-stg2.symplor.com':
            return 'https://jeanie-server-stg2.symplor.com';
        case 'jeanie-portal-stg3.symplor.com':
            return 'https://jeanie-server-stg3.symplor.com';
        default:
            return 'https://jeanie-server-stg.symplor.com';
    }
};

export const JEANIE_URL =
    NODE_ENV === 'development'
        ? 'http://localhost:8080'
        : REACT_APP_IS_STAGING
        ? 'https://jeanie-server-stg.symplor.com'
        : 'https://jeanie-server.symplor.com';

export const JEANIE_LOGIN_URL = `${JEANIE_URL}/login`;

export const JEANIE_SERVER_URL =
    NODE_ENV === 'development'
        ? 'http://localhost:8080'
        : REACT_APP_IS_STAGING
        ? stagingServerUrlByHostname()
        : 'https://jeanie-server.symplor.com';

export const systemProperties = {
    INSTANCEID: 'instance',
    HASFILTERS: 'j_hasFilters',
    JSON_SCHEMA: 'j_json_schema',
    J_JSON: 'j_json',
    J_META: 'j_meta'
};
