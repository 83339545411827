// eslint-disable-next-line import/named
import axiosClient from 'network/apiClient';
import { addQueryToURLfromObject } from 'network/utils';

// export function getTypeDetailsById(client, type, id) {
//     const endpoints = [
//         `/types/${client}/${type}`,
//         `/types/${client}/${type}/${id}`,
//         `/configs/${client}/${type}`,

//         `/configs/${client}/${type}/${id}`
//     ];

//     return Promise.all().then(([{data: user}, {data: repos}, {data: followers}, {data: followings}] )
// }

export function getTypeSchema(client, type, params) {
    const url = `/types/${client}/${type}/get`;
    return axiosClient.post(addQueryToURLfromObject(url, params));
}

export function createTypeSchema(client, type, data) {
    return axiosClient.post(`/types/${client}/${type}`, data);
}

export function updateTypeSchema(client, type, data, id) {
    return axiosClient.post(`/types/${client}/${type}/${id}`, data);
}

export function archivedTypeSchema(client, type, payload) {
    return axiosClient.post(`/types/${client}/${type}/archived`, payload);
}

export function unArchivedTypeSchema(client, type) {
    return axiosClient.post(`/types/${client}/${type}/unarchived`);
}

export function getTypeCSV(client, type) {
    return axiosClient.post(`/types/${client}/${type}/get-csv`);
}
