import { Link } from 'react-router-dom';

import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolderRounded';
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';
import NoteAddIcon from '@mui/icons-material/PostAdd';

import { addNodeUnderParent, removeNodeAtPath } from '@nosferatu500/react-sortable-tree';
import { PropTypes } from 'prop-types';
import { getNodeKey } from './helper';
import { useSnackbar } from 'notistack';

import './tree.scss';
import AlertDialog from 'views/common/AlertDialog';
import { useState } from 'react';

export const FolderBtn = ({ path, navTreeData, updateTreeDataFn }) => {
    const addNodeUnderParentRes = () => {
        const node = addNodeUnderParent({
            treeData: navTreeData,
            parentKey: path[path.length - 1],
            expandParent: true,
            getNodeKey,
            newNode: {
                title: '',
                edit: true,
                isDirectory: true,
                id: String(Date.now()),
                expanded: false,
                children: []
            },
            addAsFirstChild: false
        });

        return node.treeData;
    };

    return (
        <CreateNewFolderIcon
            sx={{
                marginLeft: '15px',
                cursor: 'pointer'
            }}
            onClick={() => updateTreeDataFn(addNodeUnderParentRes())}
        />
    );
};

const FileBtn = ({ updateNewTypeNode }) => (
    <Link to="create-type">
        <NoteAddIcon
            sx={{
                marginLeft: '15px',
                cursor: 'pointer'
            }}
            onClick={updateNewTypeNode}
        />
    </Link>
);

export const DeleteFolder = ({ node, path, navTreeData, updateTreeDataFn }) => {
    const { enqueueSnackbar } = useSnackbar();

    const deleteNodeRes = () => {
        if (node.children.length === 0) {
            const updatedTreeData = removeNodeAtPath({
                treeData: navTreeData,
                path,
                getNodeKey
            });

            return updatedTreeData;
        }

        enqueueSnackbar('Folder should be empty to be deleted', {
            variant: 'error'
        });
        return navTreeData;
    };

    return (
        <DeleteForeverRoundedIcon
            sx={{
                marginLeft: '15px',
                cursor: 'pointer'
            }}
            onClick={() => updateTreeDataFn(deleteNodeRes(), true)}
        />
    );
};

export const DeleteFile = ({ node, path, navTreeData, updateTreeDataFn, archivedTypeFn }) => {
    const [dialogStatus, setDialogStatus] = useState(false);

    const deleteNodeRes = () => {
        const updatedTreeData = removeNodeAtPath({
            treeData: navTreeData,
            path,
            getNodeKey
        });

        return updatedTreeData;
    };

    return (
        <>
            <AlertDialog
                dialogStatus={dialogStatus}
                title="Are you sure ?"
                description="It will move instance to archived."
                handleClickDisagree={() => setDialogStatus(false)}
                handleClickAgree={() => {
                    updateTreeDataFn(deleteNodeRes(), true);
                    archivedTypeFn(node);
                    setDialogStatus(false);
                }}
            />
            <DeleteForeverRoundedIcon
                sx={{
                    marginLeft: '15px'
                }}
                className="cursor_pointer"
                onClick={() => setDialogStatus(true)}
            />
        </>
    );
};

// Action Icon Add Folder & Add File
export const actionButton = (rowInfo, navTreeData, updateTreeDataFn, updateNewTypeNode, archivedTypeFn, isRootAdmin) => {
    if (rowInfo.node.edit || !isRootAdmin) {
        return [];
    }
    if (rowInfo.node.isDirectory) {
        return [
            <FolderBtn navTreeData={navTreeData} path={rowInfo.path} updateTreeDataFn={updateTreeDataFn} />,
            <FileBtn updateNewTypeNode={() => updateNewTypeNode(rowInfo)} />,
            <DeleteFolder node={rowInfo.node} navTreeData={navTreeData} path={rowInfo.path} updateTreeDataFn={updateTreeDataFn} />
        ];
    }
    return [
        <DeleteFile
            node={rowInfo.node}
            navTreeData={navTreeData}
            path={rowInfo.path}
            updateTreeDataFn={updateTreeDataFn}
            archivedTypeFn={archivedTypeFn}
        />
    ];
};

FolderBtn.propTypes = {
    path: PropTypes.array,
    navTreeData: PropTypes.object,
    updateTreeDataFn: PropTypes.func.isRequired,
    node: PropTypes.PropTypes.shape({})
};

FileBtn.propTypes = {
    updateNewTypeNode: PropTypes.func.isRequired
};
