/* eslint-disable no-unused-expressions */
import { Link } from 'react-router-dom';
import { removeNodeAtPath, changeNodeAtPath } from '@nosferatu500/react-sortable-tree';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckIcon from '@mui/icons-material/Check';
import mongoDBObjectIDGenerator from 'utils/mongodb-objectid-generator';
import CreateNewFolderIcon from '@mui/icons-material/InsertDriveFileOutlined';

export const getNodeKey = ({ node }) => node.id;

//  In case Tree is Empty
export const emptyTree = () => [
    {
        title: 'Configuration', // Name of folder/File
        edit: false, // For Addiing Input box on Tree Node
        isDirectory: true, // Check for Folder or File
        id: mongoDBObjectIDGenerator(), // ID
        expanded: false, // For Expanded the Folder
        // removeFileButton: true, // To Remove  File button
        removeCancelButton: true, // To Deleting Empty Tree Node
        children: [] // in Case Noode has child
    }
];

// To Change any Props on Tree Node (Folder/File)
export const changeNode = (navTreeData, rowInfo, key, value) => {
    const data = changeNodeAtPath({
        treeData: navTreeData,
        path: rowInfo.path,
        getNodeKey,
        newNode: { ...rowInfo.node, [key]: value }
    });

    return data;
};
// To Remove Tree Node (Folder/File)
export const deleteNode = (rowInfo, navTreeData) => {
    const data = removeNodeAtPath({
        treeData: navTreeData,
        path: rowInfo.path,
        getNodeKey
    });

    return data;
};
// To Update Tree Node (Folder/File) title
export const updateTitle = (rowInfo, navTreeData, updateTreeDataFn, updateFolderName, resetTablePagination) =>
    rowInfo.node.edit ? (
        <>
            <input
                style={{ fontSize: '1.1rem', width: '100px' }}
                onBlur={(event) => {
                    const inputValue = event.target.value;
                    const name = inputValue.trim();
                    name.length > 0
                        ? updateTreeDataFn(changeNode(navTreeData, rowInfo, 'title', name))
                        : updateTreeDataFn(deleteNode(rowInfo, navTreeData));
                }}
            />
            <CheckIcon
                onClick={() => {
                    if (rowInfo.node.title) {
                        updateFolderName(changeNode(navTreeData, rowInfo, 'edit', false), true);
                    }
                }}
            />
            {rowInfo.node.removeCancelButton ? null : (
                <CancelIcon onClick={() => updateTreeDataFn(deleteNode(rowInfo, navTreeData), true)} />
            )}
        </>
    ) : (
        <>
            {rowInfo.node.isDirectory ? (
                rowInfo.node.title
            ) : (
                <Link to={rowInfo.node.id} onClick={resetTablePagination} title={rowInfo.node.title}>
                    {rowInfo.node.title}
                </Link>
            )}
        </>
    );

// To Update Tree Node (Folder/File) pre icon
// Todo OOnly works with Theme \ Currently theme has Some error
export const updateIcon = (rowInfo) => (rowInfo.node.isDirectory ? [] : [<CreateNewFolderIcon />]);
