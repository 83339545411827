// action - customization reducer
export const SET_MENU = '@customization/SET_MENU';
export const MENU_TOGGLE = '@customization/MENU_TOGGLE';
export const MENU_OPEN = '@customization/MENU_OPEN';
export const SET_FONT_FAMILY = '@customization/SET_FONT_FAMILY';
export const SET_BORDER_RADIUS = '@customization/SET_BORDER_RADIUS';
export const UPDATE_NAVIGATION_FETCH = '@customization/UPDATE_NAVIGATION_FETCH';
export const UPDATE_NAVIGATION_SUCCESS = '@customization/UPDATE_NAVIGATION_SUCCESS';
export const SET_NEW_TYPE_NODE = '@customization/SET_NEW_TYPE_NODE';
export const SET_CURRENT_PAGE = '@customization/SET_CURRENT_PAGE';
export const SET_CURRENT_NAV = '@customization/SET_CURRENT_NAV';

// users
export const GSSO_USER_DETAILS = '@user/GSSO_USER_DETAILS';
export const SET_USER_DETAILS = '@user/SET_USER_DETAILS';
export const SET_SELECTED_CLIENT = '@user/SET_SELECTED_CLIENT';
export const SET_SELECTED_TYPE = '@user/SET_SELECTED_TYPE';
