import { Box, Typography, Button } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const LogOut = ({ userData: { displayName, email }, handleLogout }) => {
    const theme = useTheme();
    return (
        <Box
            sx={{
                position: 'fixed',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                left: 0,
                paddingTop: '12px',
                paddingBottom: '12px',
                paddingLeft: '20px',
                paddingRight: '20px',
                zIndex: 100,
                bottom: 0,
                borderTop: '1px solid #0000001F',
                background: '#FFF',
                width: 284,
                borderRight: '1px solid #0000001F'
            }}
        >
            <Typography
                variant="h1"
                component="h5"
                sx={{
                    fontSize: '14px!important',
                    fontWeight: 500,
                    lineHeight: '21px',
                    marginRight: '4px'
                }}
            >
                Hi, {displayName || email}
            </Typography>
            <Button
                disableElevation
                sx={{
                    fontSize: '14px',
                    lineHeight: '19px',
                    fontWeight: 500,
                    padding: '4px 8px',
                    borderRadius: '4px',
                    color: '#fff!important',
                    minWidth: 'auto',
                    background: theme.palette.orange.default,

                    '&:focus': {
                        outline: 'none'
                    },
                    '&:hover': {
                        background: theme.palette.orange.dark
                    }
                }}
                onClick={handleLogout}
            >
                Logout
            </Button>
        </Box>
    );
};

export default LogOut;
