import axiosClient from '../apiClient';
import { addQueryToURLfromObject } from 'network/utils';

export function getTypeInstanceById(client, type, id, params) {
    const url = `/configs/${client}/${type}/${id}/get`;
    return axiosClient.post(addQueryToURLfromObject(url, params));
}

export function getTypeInstanceByFilters(client, type, filters = {}) {
    const url = `/configs/${client}/${type}/get`;
    return axiosClient.post(addQueryToURLfromObject(url, filters));
}

export function createTypeInstance(client, type, id, data) {
    return axiosClient.post(`/configs/${client}/${type}`, data);
}

export function updateTypeInstance(client, type, id, data, dbEnv = 'drafts', params) {
    const url = `/configs/${client}/${type}/${id}?db_env=${dbEnv}`;
    return axiosClient.post(addQueryToURLfromObject(url, params), data);
}
export function publishTypeInstance(client, type, id) {
    return axiosClient.post(`/configs/${client}/${type}/${id}/publish`);
}

export function bulkPublishTypeInstance(client, type, ids) {
    return axiosClient.post(`/configs/${client}/publish/bulk`, ids);
}

export function deleteTypeInstance(client, type, id) {
    return axiosClient.post(`/configs/${client}/${type}/${id}/delete`);
}

export function triggerChildEndPoints(client, type, id, dbEnv = '') {
    return axiosClient.post(`/configs/${client}/${type}/${id}/trigger-child-end-points?db_env=${dbEnv}`);
}

export function createBulkInstanceViaCSV(client, type, formData) {
    return axiosClient.post(`/configs/${client}/${type}/csv-bulk-instertion`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
}
