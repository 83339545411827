// action - state management
import * as actionTypes from './actions';

// ==============================|| CUSTOMIZATION REDUCER ||============================== //

const userReducer = (state = {}, action) => {
    switch (action.type) {
        case actionTypes.SET_USER_DETAILS:
            return {
                ...state,
                ...action.payload
            };
        case actionTypes.SET_SELECTED_CLIENT:
            return {
                ...state,
                clients: action.payload
            };
        case actionTypes.SET_SELECTED_TYPE:
            return {
                ...state,
                type: action.payload
            };
        default:
            return state;
    }
};

export default userReducer;
