import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useParams, useNavigate } from 'react-router-dom';

// material-ui
import { styled, useTheme } from '@mui/material/styles';
import { AppBar, Box, CssBaseline, Toolbar, useMediaQuery } from '@mui/material';

// project imports
import Breadcrumbs from 'ui-component/extended/Breadcrumbs';
import Header from './Header';
import Sidebar from './Sidebar';
import Customization from '../Customization';
import navigation from 'menu-items';
import { drawerWidth } from 'store/constant';
import { SET_MENU, SET_SELECTED_TYPE } from 'store/actions';
import { isEmptyOrNull } from 'utils/validations';

// assets
import { IconChevronRight } from '@tabler/icons';
import { getClientDisplayName } from 'utils/common';
// import { letterSpacing } from '@mui/material/node_modules/@mui/system';

// styles
const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
    ...theme.typography.mainContent,
    ...(!open && {
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        }),
        [theme.breakpoints.up('md')]: {
            marginLeft: -(drawerWidth - 20),
            width: `calc(100% - ${drawerWidth}px)`
        },
        [theme.breakpoints.down('md')]: {
            marginLeft: '20px',
            width: `calc(100% - ${drawerWidth}px)`,
            padding: '16px'
        },
        [theme.breakpoints.down('sm')]: {
            marginLeft: '10px',
            width: `calc(100% - ${drawerWidth}px)`,
            padding: '16px',
            marginRight: '10px'
        }
    }),
    ...(open && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen
        }),
        marginLeft: 0,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        width: `calc(100% - ${drawerWidth}px)`,
        [theme.breakpoints.down('md')]: {
            marginLeft: '20px'
        },
        [theme.breakpoints.down('sm')]: {
            marginLeft: '10px'
        }
    })
}));

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = () => {
    const theme = useTheme();
    const params = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    // eslint-disable-next-line no-undef
    // const [validUser, setValidUser] = useState(false);
    const matchDownMd = useMediaQuery(theme.breakpoints.down('lg'));
    const [clients, setClients] = useState(null);
    // Handle left drawer
    const leftDrawerOpened = useSelector((state) => state.customization.opened);
    const userData = useSelector((state) => state.user);

    const handleLeftDrawerToggle = () => {
        dispatch({ type: SET_MENU, opened: !leftDrawerOpened });
    };
    // #todo , check client is authorized

    useEffect(() => {
        if (isEmptyOrNull(userData)) {
            const pathName = window.location.pathname;
            window.location.href = `/login?ru=${pathName}`;
        }
        dispatch({ type: SET_SELECTED_TYPE, payload: params.client });
    }, []);

    useEffect(() => {
        let listOfClients = null;
        if (userData && userData.clients) {
            const { clients } = userData;
            listOfClients = Object.keys(clients).reduce((acc, key) => {
                const clientObj = clients[key];
                if (userData.admin || clientObj.admin || (clientObj.details.j_id && clientObj.directAccess)) {
                    acc.push({
                        value: key,
                        displayName: getClientDisplayName(key, clients) // #todo , need to get the userDetails as well in case resolve_ref = true
                    });
                }
                return acc;
            }, []);
            setClients(listOfClients);
        }
    }, [userData]);

    useEffect(() => {
        dispatch({ type: SET_MENU, opened: !matchDownMd });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [matchDownMd]);

    // eslint-disable-next-line no-debugger

    const dropDownData = {
        list: clients,
        selectedValue: params.client
    };

    // #todo :  use to fetch the Data for navigation from jeanie server here and
    // pass that to Sidebar

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            {/* <div> production :: {process.env.NODE_ENV}</div>
            <div> it should be come here {process.env.REACT_APP_IS_STAGING}</div> */}
            {/* header */}
            <AppBar
                enableColorOnDark
                position="fixed"
                color="inherit"
                elevation={0}
                sx={{
                    bgcolor: theme.palette.background.default,
                    transition: leftDrawerOpened ? theme.transitions.create('width') : 'none'
                }}
            >
                <Toolbar>
                    <Header handleLeftDrawerToggle={handleLeftDrawerToggle} dropDown={dropDownData} />
                </Toolbar>
            </AppBar>

            {/* drawer */}
            {/* will get data for rendering cleints and types once user is validated and we selected the client */}
            <Sidebar drawerOpen={leftDrawerOpened} drawerToggle={handleLeftDrawerToggle} dropDown={dropDownData} />

            {/* main content */}
            <Main theme={theme} open={leftDrawerOpened}>
                <div
                    style={{
                        padding: '0px',
                        margin: '20px 0',
                        marginTop: '0PX',
                        backgroundColor: '#fff',
                        borderRadius: '12px',
                        height: 'calc(100vh - 100px)',
                        width: '96%',
                        overflow: 'auto'
                    }}
                >
                    {/* breadcrumb */}
                    <Breadcrumbs separator={IconChevronRight} navigation={navigation} icon title rightAlign />
                    <Outlet />
                </div>
            </Main>
            {/* <Customization /> */}
        </Box>
    );
};

export default MainLayout;
