export const isEmptyOrNull = (obj) => {
    if (!obj) {
        return true;
    }
    if (Array.isArray(obj)) {
        return obj.length === 0;
    }
    return Object.keys(obj).length === 0;
};

export function hasValue(v) {
    if (typeof v !== 'undefined' && v && v !== '') {
        return true;
    }
    return false;
}
